// external imports
import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'

// helpers
import { up, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData, extractNodesFromDataApplyLangs } from '../lib/helpers'
import { applyLang, applyLangSingle } from '../lib/intl'
import injectIntl from '../lib/intl/injectIntl'

// components
import SEO from '../components/SEO'
import T from '../lib/intl/T'
import IntlContext from '../lib/intl/IntlContext'
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Listing,
  WaveImage,
  ContactFormSection,
  Button,
} from '../components'
import PostItem from '../components/PostItem'
import PostsListing from '../components/PostsListing'
import { Helmet } from 'react-helmet'

let savedState = null

class Blog extends React.Component {
  state = {
    limit: 10,
  }

  componentDidMount() {
    if (savedState) {
      this.setState({ ...savedState })
    }
  }

  componentWillUnmount() {
    savedState = { ...this.state }
  }

  render() {
    const {
      data,
      location: { pathname },
      intl: { language, t },
    } = this.props
    const { limit } = this.state

    let { posts } = extractNodesFromDataApplyLangs(data, language)

    const firstPost = posts.shift()
    posts = posts.slice(0, limit)

    return (
      <>
        <SEO
          title={t('blog - meta title')}
          description={t('blog - meta desc')}
          pathname={pathname}
        />

        <Title.PageTitle display="none">Blog</Title.PageTitle>
        <Gap.Top />
        <Col maxWidth="1010px" alignSelf="center" alignItems="flex-start" width="100%">
          {firstPost && <PostItem post={firstPost} big />}

          <PostsListing posts={posts} />

          {limit < posts.length + 1 && (
            <Col alignItems="center" width="100%">
              <Button.Dashed
                onClick={() => {
                  this.setState(state => ({
                    limit: state.limit + 10,
                  }))
                }}
              >
                <T>další články</T>
              </Button.Dashed>
            </Col>
          )}
        </Col>
        <Gap gap="80px" />
        <ContactFormSection />
      </>
    )
  }
}

export default injectIntl(Blog)

export const pageQuery = graphql`
  query ($language: String!) {
    posts: allPosts(
      sort: { fields: when, order: DESC }
      filter: {
        state: { eq: "published" }
        lang: { eq: $language }
        isOnFoundationPage: { eq: false }
        isFoundationEvent: { ne: true }
        isChange: { ne: true }
      }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
    site {
      siteMetadata {
        siteUrl: url
      }
    }
  }
`
